import { Company, User } from '../../../../generated/graphql';

type MockedCompany = Pick<
  Company,
  | 'id'
  | 'name'
  | 'domainName'
  | '__typename'
  | 'createdAt'
  | 'address'
  | 'employees'
  | 'linkedinUrl'
  | '_activityCount'
> & {
  accountOwner: Pick<
    User,
    | 'id'
    | 'email'
    | 'displayName'
    | 'avatarUrl'
    | '__typename'
    | 'firstName'
    | 'lastName'
  > | null;
};

export const mockedCompaniesData: Array<MockedCompany> = [
  {
    id: 'twenty-04b2e9f5-0713-40a5-8216-82802401d33e',
    domainName: 'vstaff.dev',
    name: 'vStaff',
    createdAt: '2023-08-02T10:12:42.33625+00:00',
    address: 'Austin',
    employees: 2,
    _activityCount: 0,
    accountOwner: null,
    __typename: 'Company',
  },
];
